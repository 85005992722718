/*custom style for react table*/
.rt-thead {
  background-color: #fff;
  padding: 6px;
  font-weight: bold;
  border-bottom: 2px solid #222;
}

.rt-tbody {
  background-color: #fff;
}

/*custom buttons*/
.custom-primary {
  color: #fff !important;
  background-color: #223f49 !important;
  border-color: #223f49 !important;
}

.rating > span > span {
  margin: 2px;
}

.fas.fa-star.fa-1x::before {
  color: #daa520;
}

.chat .row > div:nth-child(2) {
  max-width: 65% !important;
}

.scoring-modal .modal-header h5 {
  width: 100%;
  text-align: center;
}

.ReactTable .rt-noData {
  display: none !important;
}

.ReactTable.payment .rt-thead .rt-resizable-header-content {
  white-space: initial !important;
}

label.required::after {
  content: " *";
  color: red;
}

.csv-reader-input label {
  width: 100%;
  cursor: pointer;
}

.lds-ring {
  display: inline-block;
  height: 14px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  margin-left: -8px;
  border: 0.5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type="file"]#image {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: rgb(153, 153, 153);
}

label.label-input-file {
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #223f49;
  font-size: 16px;
  color: #fff;
}

i.icon-trash {
  background-color: #f85252;
  color: #fff;
  padding: 7px;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}

li.notifications .show > div {
  left: unset !important;
  right: 0 !important;
}
li.notifications .show > div > div {
  max-height: 250px;
  overflow-y: scroll;
  border-bottom: 1px solid #d2d2d2;
}

li.notifications li a::after {
  content: none !important;
}

span.mark-all-as-read {
  color: #2993b8;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  padding: 6px 6px 6px 24px;
}

#mute-notifications {
  color: #2993b8;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  padding: 6px 6px 6px 24px;
}

.editor-wrapper {
  border: 1px solid #e4e7ea;
}

.editor {
  padding: 0.75rem;
}

@font-face {
  font-family: "Roboto";
  src: url("https://fonts.googleapis.com/css2?family=Roboto");
}

.DraftEditor-root {
  font-family: Roboto;
}
