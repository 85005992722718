// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/all.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~@fortawesome/fontawesome-free/css/all.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import React Widgets CSS File
@import "~react-widgets/dist/css/react-widgets.css";

// Import Main styles for this application
@import "./scss/style.scss";
