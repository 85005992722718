// Here you can add other styles
// Chat box (launcher)
#sc-launcher {
  .sc-launcher {
    bottom: 55px;

    img {
      bottom: 55px;
    }
  }

  .sc-header--img {
    width: 55px;
  }

  .sc-chat-window {
    height: calc(80% - 120px);
    bottom: 120px;

    .sc-message-list {
      padding-top: 0;
      margin-bottom: 50px;
    }

    form {
      display: none;
    }
  }
}

#players-date-range-picker {
  .DayPickerInput {
    width: 49.5%;
    input {
      width: 100%;
      background: white;
      border-color: #e4e7ea !important;
    }
  }
}
.InputFromTo .DayPickerInput-Overlay {
  margin-left: 0 !important;
  z-index: 3;
}
footer{
  position: fixed;
  bottom: 0;
 // width: calc(100% - 200px);
  height: 50px;
  width: 100%;
}
main{
  margin-bottom: 50px;
  z-index: 1;
}


.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  &:focus-within {
    color: #5c6873;
    background-color: #fff;
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
  }
  input {
  flex: 1;
  color: #5c6873;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
  &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #223f49;
  .tag-title {
    margin-top: 3px;
  }
  .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #223f49;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  }
}
